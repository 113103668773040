.Basicinformation {
  display: flex;
  flex-wrap: wrap;
}
.Basicinformation > div {
  width: 50%;
  padding: 10px 0;
}
.Basicinformation .information {
  display: flex;
  flex-wrap: wrap;
}
.Basicinformation .information .information-item {
  width: 50%;
}
.lessonNum {
  display: flex;
  padding: 1rem;
}
.lessonNum span {
  padding: 0 1rem;
}
.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}
.lessNumcount span {
  width: 10rem;
}
.custom-tree-node {
  display: flex;
  align-items: center;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40rem;
  font-size: 14px;
}
.ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 39.85rem;
  font-size: 14px;
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-tree-node .el-form-item {
  margin-bottom: 0px;
}
.qualificationImageId .el-image {
  display: flex;
  height: 500px;
}
.dataqualificationImageId {
  margin-left: -2px;
}
.imgUpload {
  display: flex;
  justify-content: center;
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
